import {Dispatch} from "redux";

import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IOfferDetail} from "../../../offer/types/IOfferDetail";

const FETCH_INVESTMENT_OFFER_MAP_OFFER_DETAIL = "investment_offer_map/FETCH_OFFER_DETAIL";
export const fetchInvestmentOfferMapSelectedOfferTypes = createRequestActionTypes(FETCH_INVESTMENT_OFFER_MAP_OFFER_DETAIL);

export const fetchInvestmentOfferMapSelectedOffer = (offerId: number) => (dispatch: Dispatch) => {
    dispatch({type: fetchInvestmentOfferMapSelectedOfferTypes.start});

    const url = apiV2Link.offer.detail(Scenario.OFFER_DETAIL_MAP, {offerId});

    return getRequest({}, url).then((result: IOfferDetail) => {
        dispatch({type: fetchInvestmentOfferMapSelectedOfferTypes.success, result});
        return result;
    });
};
